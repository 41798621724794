import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useForm } from "react-hook-form";
import Campos from "../../../../components/CamposTexto";
import axios from "axios";

const messages = {
  req: "Este campo es obligatorio",
  telefono: "Solo puede contener números",
};

const patterns = {
  nombre: /^[\s\S]*$/,
  direccion: /^[\s\S]*$/,
  telefono: /^[0-9]+$/,
};

const OficinaNueva = ({ open, handleClose, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("");

  const showMessage = (msg, color = "green") => {
    setMessage(msg);
    setMessageColor(color);
    setTimeout(() => {
      setMessage("");
      if (color === "green") {
        handleClose();
      }
    }, 3000);
  };

  useEffect(() => {
    if (data) {
      console.log("Data:", data.Nivel);
      reset({
        nombre: data.Nombre || "",
        direccion: data.Direccion || "",
        telefono: data.Telefono || "",
        nivel: data.Nivel || "", // Ensure 'nivel' is initialized as an empty string or valid value
      });
    } else {
      reset({
        nombre: "",
        direccion: "",
        telefono: "",
        nivel: "", // Reiniciar 'Nivel' al abrir un formulario en blanco
      });
    }
  }, [data, reset]);

  const onSubmit = async (formData) => {
    try {
      const url = data && data.OficinaID
        ? `/oficinas/Oficinas/${data.OficinaID}`
        : "/oficinas/OficinasNuevas";

      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      if (response.status === 200 || response.status === 201) {
        showMessage(response.data.message || "Operación exitosa", "green");
      } else {
        showMessage(response.data.error || "Error en la operación", "red");
      }
    } catch (error) {
      console.error("Error al enviar datos:", error);
      showMessage(
        error.response?.data?.error || "Error en la operación",
        "red"
      );
    }
  };

  useEffect(() => {
    if (open) {
      setMessage("");
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div style={{ maxWidth: "500px", margin: "auto", padding: "20px", backgroundColor: "white", borderRadius: "8px", outline: "none" }}>
        <DialogTitle style={{ display: "flex", alignItems: "center", gap: "8px", color: "#f57c00" }}>
          <WarningRoundedIcon /> {data && data.OficinaID ? "Modificar Oficina" : "Nueva Oficina"}
        </DialogTitle>
        <Divider />
        <DialogContent style={{ marginTop: "10px" }}>
          <form onSubmit={handleSubmit(onSubmit)} id="form-modificar-oficina" autoComplete="off">
            <div style={{ marginBottom: "16px" }}>
              <Campos
                htmlFor="nombre"
                label="Nombre(s)"
                register={register}
                required
                pattern={patterns.nombre}
                messages={messages}
                errors={errors}
                tipo="text"
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              <Campos
                htmlFor="direccion"
                label="Dirección"
                register={register}
                required
                pattern={patterns.direccion}
                messages={messages}
                errors={errors}
                rows={4}
              />
            </div>
            <div style={{ marginBottom: "16px" }}>
              <Campos
                htmlFor="telefono"
                label="Teléfono"
                register={register}
                required
                pattern={patterns.telefono}
                messages={messages}
                errors={errors}
                tipo="text"
                maxLength={10}
                minLength={10}
              />
            </div>

            {/* Select component */}
            <div style={{ marginBottom: "16px" }}>
              <FormControl fullWidth required>
                <InputLabel id="select-label">Nivel</InputLabel>
                <Select
                  labelId="select-label"
                  {...register("nivel")} // Register the 'nivel' field with react-hook-form
                  label="Nivel"
                  defaultValue={data ? data.Nivel : ""} // Set the initial value based on data
                >
                  <MenuItem value="">Seleccione un nivel</MenuItem>
                  <MenuItem value="1">Premium</MenuItem>
                  <MenuItem value="2">Impulsa</MenuItem>
                  <MenuItem value="3">Futuria</MenuItem>
                  <MenuItem value="4">Oficina</MenuItem>
                </Select>
              </FormControl>
            </div>

            {message && <p style={{ color: messageColor, textAlign: "center" }}>{message}</p>}
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", gap: "16px" }}>
          <Button variant="contained" color="primary" type="submit" form="form-modificar-oficina">
            Guardar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Modal>
  );
};

export default OficinaNueva;
