import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/joy/Table";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/joy/Button";
import OficinaNueva from "./OficinasNueva";
import EliminarOficina from "./EliminarOficina";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";

const TablaOficinas = () => {
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Nombre");
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const fetchOficinas = useCallback(async () => {
    try {
      const response = await axios.get("/oficinas/Oficinas");
      const oficinas = response.data || [];
      setRows(oficinas);
      console.log("Datos obtenidos:", oficinas);

      oficinas.forEach((row) => {
        //console.log("UsuarioID:", row.UsuarioID);
        if (row.UsuarioID !== "0" && row.UsuarioMod) {
          /*console.log(
            "Nombre completo del usuario:",
            row.UsuarioMod.Nombres,
            row.UsuarioMod.ApellidoPaterno
          );*/
        }
      });
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchOficinas();
  }, [fetchOficinas]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedRows = [...rows].sort((a, b) => {
      const aValue = a[property]?.toString().toLowerCase() || "";
      const bValue = b[property]?.toString().toLowerCase() || "";

      if (aValue < bValue) return isAsc ? -1 : 1;
      if (aValue > bValue) return isAsc ? 1 : -1;
      return 0;
    });
    setRows(sortedRows);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleModificar = (row) => {
    setModalData(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setModalData(null);
    setOpenModal(false);
    setOpenDeleteModal(false);
    fetchOficinas();
  };

  const filteredRows = rows.filter((row) => {
    const oficina = row.Nombre?.toLowerCase() || "";
    const direccion = row.Direccion?.toLowerCase() || "";
    const telefono = row.Telefono?.toLowerCase() || "";
    const nivel = row.NivelNombre?.toLowerCase() || "";
    const Modificado =
      row.UsuarioID === "0"
        ? "Administrador"
        : row.UsuarioMod
        ? `${(row.UsuarioMod.Nombres || "").toLowerCase()} ${(
            row.UsuarioMod.ApellidoPaterno || ""
          ).toLowerCase()}`
        : "";
    const updatedAt = row.updatedAt?.toString().toLowerCase() || "";
    const searchTermLower = searchTerm.toLowerCase();

    return (
      oficina.includes(searchTermLower) ||
      direccion.includes(searchTermLower) ||
      telefono.includes(searchTermLower) ||
      nivel.includes(searchTermLower) ||
      Modificado.includes(searchTermLower) ||
      updatedAt.includes(searchTermLower)
    );
  });

  const handleExpandRow = (row) => {
    setModalData(modalData === row ? null : row);
  };

  return (
    <div>
      <div className="bg-beige container-wrapper">
        <div
          style={{
            paddingBottom: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              width: "200px",
              padding: "5px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "10px",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModalData(null);
              setOpenModal(true);
            }}
          >
            Nueva Oficina
          </Button>
        </div>
        {rows.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th onClick={() => handleRequestSort("Nombre")}>
                  Nombre
                  {orderBy === "Nombre" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("Direccion")}>
                  Dirección
                  {orderBy === "Direccion" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("Telefono")}>
                  Teléfono
                  {orderBy === "Telefono" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("Nivel")}>
                  Nivel
                  {orderBy === "Nivel" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("UsuarioID")}>
                  Modificado Por:
                  {orderBy === "UsuarioID" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("updatedAt")}>
                  Última modificación:
                  {orderBy === "updatedAt" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th onClick={() => handleRequestSort("StatusID")}>
                  Status
                  {orderBy === "StatusID" &&
                    (order === "asc" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    ))}
                </th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.map((row) => (
                <React.Fragment key={row.OficinaID}>
                  <tr>
                    <td>{row.Nombre}</td>
                    <td>
                      <Chip
                        label={
                          modalData === row ? "Ocultar dirección" : "Ver más"
                        }
                        icon={modalData === row ? <RemoveIcon /> : <AddIcon />}
                        onClick={() => handleExpandRow(row)}
                        style={{ fontWeight: "bold" }}
                      />
                    </td>
                    <td>{row.Telefono}</td>
                    <td>{row.NivelNombre}</td>
                    <td>
                      {row.UsuarioID === "0"
                        ? "Administrador"
                        : row.UsuarioMod
                        ? `${row.UsuarioMod.Nombres || ""} ${
                            row.UsuarioMod.ApellidoPaterno || ""
                          }`
                        : ""}
                    </td>
                    <td>{row.updatedAt}</td>
                    <td>
                      <Chip
                        label={row.Catalogo ? row.Catalogo.Nombre : ""}
                        color={
                          row.Catalogo && row.Catalogo.Nombre === "ACTIVO"
                            ? "success"
                            : "error"
                        }
                      />
                    </td>
                    <td>
                      <Tooltip title="Modificar oficina">
                        <EditIcon
                          onClick={() => handleModificar(row)}
                          style={{ cursor: "pointer", color: "blue" }}
                        />
                      </Tooltip>

                      <Tooltip title="Borrar oficina">
                        <DeleteIcon
                          onClick={() => {
                            setModalData(row);
                            setOpenDeleteModal(true);
                          }}
                          style={{
                            cursor: "pointer",
                            marginLeft: "10px",
                            color: "red",
                          }}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                  {modalData === row && (
                    <tr>
                      <td colSpan="7">
                        <div>
                          <strong>Dirección completa:</strong> {row.Direccion}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No existen datos</p>
        )}
        <OficinaNueva
          open={openModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
        <EliminarOficina
          open={openDeleteModal}
          onClose={handleCloseModal}
          data={modalData}
        />
      </div>
    </div>
  );
};

export default TablaOficinas;
