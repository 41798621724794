import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../TextInput";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ProgramaSelect from "../ProgramasSelect";
import LocalidadSelect from "../LocalidadSelect";
import CESISelect from "../CESISelect";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MejoravitCampos = ({ selectedServicio, clienteID, theme }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
    getValues,
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [Programa, setPrograma] = useState([]);
  const [modalidad, setModalidad] = useState([]);
  const [Localidad, setLocalidad] = useState([]);
  const [EsGerente, setEsGerente] = useState(true); // Inicializar como booleano
  const [CESI, setCESI] = useState([]);
  const [foto, setFoto] = useState([]);
  const [apertura, setAPertura] = useState([]);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEsGerente = async () => {
      try {
        const response = await axios.get("/select/admin", {
          withCredentials: true,
        });
        // Asigna el valor de `EsGerente` a un estado o realiza alguna acción
        console.log(response);
        setEsGerente(response.data.Permisos); // Ajusta esto según tu lógica
      } catch (error) {
        console.error("Error al obtener EsGerente:", error);
      }
    };

    fetchEsGerente();
  }, []); // Asegúrate de pasar una lista de dependencias correcta

  useEffect(() => {
    const fetchData = async () => {
      if (clienteID) {
        try {
          const response = await axios.get(
            `/services/BuscarMejoravit/${clienteID}`,
            {
              withCredentials: true,
            }
          );
          console.log("La respuesta es", response);

          setPrograma(response.data.ProgramaID);
          setLocalidad(response.data.MunicipioID);
          setCESI(response.data.CESI_Ingresado);
          setFoto(response.data.Foto || "");
          setAPertura(response.data.Apertura || "");
          setModalidad(response.data.Modalidad);

          setMessage(response.data.Message || "");
          reset(response.data);
        } catch (error) {
          //console.error("Error al cargar los datos del cliente", error);
          setSubmitError("Hubo un error al cargar los datos del cliente.");
          setMessage("Algunos de los campos esta incorrecto o hace falta");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [clienteID, reset]);

  useEffect(() => {
    if (selectedServicio) {
      const fieldsToReset = [
        "programa",
        "localidad",
        "cesi",
        "correo",
        "Porcent",
        "fechaRecepcion",
        "envioExpedienteFecha",
        "fechaEntregaCESI",
        "fechaNOT",
        "firmaEntregaNot",
        "firmaContrato",
        "foto",
        "apertura",
        "validacion",
        "solicitudDescarga",
        "descargaTarjeta",
        "fecha2doDeposito",
        "credito",
        "saldoTarjeta",
        "pagoCliente",
        "cobro35",
        "GastosAdmin",
      ];

      const resetSelective = () => {
        const resetValues = fieldsToReset.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});

        reset((prevValues) => ({ ...prevValues, ...resetValues }));
      };

      resetSelective();
    }
  }, [selectedServicio, reset]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const linkTo = `/Documentos/${clienteID}/${selectedServicio}`;

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    setSubmitError(null);
    console.log("Respuesta: 1", data);
    try {
      const response = await axios.post(
        `/services/GuardarMejoravit/${clienteID}`,
        data,
        { withCredentials: true }
      );

      setMessage(response.data.message);
      return response;
    } catch (error) {
      setSubmitError("Hubo un error al guardar los datos. Inténtalo de nuevo.");
      setMessage(error.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  // Función para manejar la validación antes de ejecutar handleSubmit
  const customSubmit = async () => {
    const isValid = await trigger(); // Validar todos los campos manualmente

    if (!isValid) {
      // Si no es válido, hacer algo antes de que no se ejecute el submit
      const errorMessage = `¡Error! Algunos campos están vacíos o son incorrectos:
      • Modalidad
      • Programa
      • Entidad
      • Solicitar Apertura
    
    Por favor, verifica estos campos e intenta nuevamente.
    `;
      alert(errorMessage);
      return; // No ejecutamos handleSubmit si la validación falla
    }

    // Si la validación pasa, ejecutar handleSubmit
    handleSubmit(onSubmit)();
  };

  const handleClick = async () => {
    try {
      const formData = getValues(); // Obtén los valores del formulario
      const result = await onSubmit(formData); // Ejecuta el submit con los valores del formulario
      console.log("Respuesta: 2", result);

      // Verifica si el resultado es exitoso con un código HTTP 2xx
      if (result && result.status >= 200 && result.status < 300) {
        console.log("Fue exitoso");
        // Redirigir al enlace deseado
        navigate(linkTo);
      } else {
        console.error("Estado HTTP inesperado:", result?.status);
        const errorMessage = `¡Error! Algunos campos están vacíos o son incorrectos:
          • Modalidad
          • Programa
          • Entidad
          • Solicitar Apertura
        Por favor, verifica estos campos e intenta nuevamente.
        `;
        alert(errorMessage);

        // Manejo adicional si es necesario
      }
    } catch (error) {
      // Manejo de errores generales
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error("Error 400: Solicitud incorrecta", error.response.data);
        } else if (status === 404) {
          console.error(
            "Error 404: Recurso no encontrado",
            error.response.data
          );
        } else {
          console.error(`Error ${status}:`, error.response.data);
        }
      } else {
        // Si el error es de red o algún fallo inesperado
        console.error(
          "Error de red o fallo de conexión:",
          error.message || error
        );
      }
    }
  };

  const severity = submitError ? "error" : message ? "success" : "";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <hr style={{ margin: "20px 0", border: "2px solid #17202a" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexDirection={isSmallScreen ? "column" : "row"}
      >
        <h1>Datos de Mejoravit</h1>

        <Box>
          <Tooltip title="Subir documentos">
            <IconButton
              size="large" // Tamaño grande
              style={{
                color: "#1976d2", // Cambia el color del ícono si es necesario
                backgroundColor: "#e3f2fd", // Color de fondo para hacerlo más visible
                borderRadius: "50%", // Forma circular para mayor impacto visual
                padding: "16px", // Espaciado adicional para que el ícono sea más grande
              }}
              onClick={handleClick} // Ejecuta la función al hacer clic
            >
              <NoteAddIcon style={{ fontSize: 40 }} />{" "}
              {/* Ajusta el tamaño del ícono aquí */}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <form onSubmit={(e) => e.preventDefault()}>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <FormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={!!errors.modalidad} // Indicar si hay error
            >
              <InputLabel id="modalidad-label" style={{ color: "#000000" }}>
                Modalidad *
              </InputLabel>
              <Select
                id="modalidad"
                labelId="modalidad-label"
                defaultValue={modalidad || ""} // Asegúrate de usar 'value' en lugar de 'defaultValue'
                onChange={(e) => setValue("modalidad", e.target.value)} // Actualiza el valor en react-hook-form
                {...register("modalidad", {
                  required: "La modalidad es obligatoria", // Mensaje de error
                })}
                style={{
                  background: theme.palette.background.default, // Fondo
                  color: "#000000", // Texto
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: theme.palette.background.default, // Fondo del menú
                    },
                  },
                }}
                renderValue={(selected) => (
                  <span style={{ color: "#000000" }}>
                    {selected === ""
                      ? "Seleccione una opción"
                      : selected === 1
                      ? "Tradicional"
                      : "Expres"}
                  </span>
                )}
              >
                <MenuItem value="" style={{ color: "#000000" }} disabled>
                  Seleccione una opción
                </MenuItem>
                <MenuItem value={1} style={{ color: "#000000" }}>
                  Tradicional
                </MenuItem>
                <MenuItem value={2} style={{ color: "#000000" }}>
                  Expres
                </MenuItem>
              </Select>
              {/* Mensaje de error con ícono */}
              {errors.modalidad && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                    color: "white",
                  }}
                >
                  <IconButton style={{ padding: 0, marginRight: 5 }} disabled>
                    <ErrorOutlineIcon style={{ color: "red" }} />
                  </IconButton>
                  <span>{errors.modalidad.message}</span>
                </div>
              )}
            </FormControl>
          </Box>
          <Box flex={1}>
            <ProgramaSelect
              required={true}
              register={register}
              errors={errors}
              Nombre="programa"
              color={theme.palette.background.default}
              Letras="#000000"
              defaultValue={Programa} // Ensure 'Programa' is an ArticuloID here
            />
          </Box>

          <Box flex={1}>
            <LocalidadSelect
              required={true}
              register={register}
              errors={errors}
              Nombre="Entidad"
              color={theme.palette.background.default}
              Letras={"#000000"}
              Localidad={Localidad}
            />
          </Box>

          <Box flex={1}>
            <CESISelect
              Gerente={EsGerente}
              register={register}
              errors={errors}
              Nombre="cesi"
              color={theme.palette.background.default}
              Letras={"#000000"}
              defaultValue={CESI}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Porcent"
              label="%"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El porcentaje debe ser un número válido",
                },
              }}
              maxLength={100}
              errors={errors}
              fullWidth
            />
          </Box>

          {/* Campos de Fecha */}
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaRecepcion"
              label="Fecha de Recepción"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="envioExpedienteFecha"
              label="Envío Expediente Fecha"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaEntregaCESI"
              label="Fecha Entrega CESI"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fechaNOT"
              label="Fecha Notificacion"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="firmaEntregaNot"
              label="Fecha Entrega Acuse"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="firmaContrato"
              label="Firma Contrato"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>

          {/* Selección de Foto */}
          <Box flex={1}>
            <FormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={!!errors.foto}
            >
              <InputLabel id="foto-label" style={{ color: "#000000" }}>
                Foto
              </InputLabel>
              <Select
                readOnly={EsGerente}
                id="foto"
                labelId="foto-label"
                defaultValue={foto || ""}
                {...register("foto")}
                style={{
                  background: theme.palette.background.default, // Usa el color de fondo del tema
                  color: "#000000", // Color del texto
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                    },
                  },
                }}
                renderValue={(selected) => (
                  <span style={{ color: "#000000" }}>
                    {selected === ""
                      ? "Seleccione una opción"
                      : selected === 1
                      ? "Sí"
                      : "No"}
                  </span>
                )}
              >
                <MenuItem value="" style={{ color: "#000000" }} disabled>
                  Seleccione una opción
                </MenuItem>
                <MenuItem value={1} style={{ color: "#000000" }}>
                  Sí
                </MenuItem>
                <MenuItem value={2} style={{ color: "#000000" }}>
                  No
                </MenuItem>
              </Select>
              <FormHelperText>{errors.foto?.message || ""}</FormHelperText>{" "}
              {/* Muestra el mensaje de error si existe */}
            </FormControl>
          </Box>

          {/* Más campos */}
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="validacion"
              label="Validación"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="solicitudDescarga"
              label="Solicitud de Descarga"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="descargaTarjeta"
              label="Descarga Tarjeta"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              type="date"
              id="fecha2doDeposito"
              label="Fecha de 2do Depósito"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              type="text"
              id="correo"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, // Validación básica de formato de correo electrónico
                  message: "El correo electrónico debe ser válido.",
                },
              }}
              label="Correo"
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Mejoravit solo para ti
        </Typography>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="PorcentajeCobro"
              label="Porcentaje cobro"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El porcentaje de cobro debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>

          <Box flex={1}>
          <FormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={!!errors.apertura}
            >
              <InputLabel id="apertura-label" style={{ color: "#000000" }}>
                Solicitar Apertura *
              </InputLabel>
              <Select
                id="apertura"
                labelId="apertura-label"
                defaultValue={apertura || ""}
                {...register("apertura", {
                  required: "Este campo es obligatorio", // Mensaje de error
                })}
                style={{
                  background: theme.palette.background.default, // Usa el color de fondo del tema
                  color: "#000000", // Color del texto
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                    },
                  },
                }}
                renderValue={(selected) => (
                  <span style={{ color: "#000000" }}>
                    {selected === ""
                      ? "Seleccione una opción"
                      : selected === "1"
                      ? "Sí"
                      : "No"}
                  </span>
                )}
              >
                <MenuItem value="" style={{ color: "#000000" }} disabled>
                  Seleccione una opción
                </MenuItem>
                <MenuItem value="1" style={{ color: "#000000" }}>
                  Sí
                </MenuItem>
                <MenuItem value="2" style={{ color: "#000000" }}>
                  No
                </MenuItem>
              </Select>
              <FormHelperText>{errors.apertura?.message || ""}</FormHelperText>{" "}
              {/* Muestra el mensaje de error si existe */}
            </FormControl>
            </Box>

            <Box flex={1}>
            <TextInput
              id="nivelOficina"
              label="Nivel Oficina"
              type="text"
              register={register}
              color={theme.palette.background.default}
              Gerente={true}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Formato Contabilidad
        </Typography>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="credito"
              label="Crédito"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El crédito debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="saldoTarjeta"
              label="Saldo Tarjeta"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El saldo debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="pagoCliente"
              label="Pago Cliente 20%"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El pago debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="cobro35"
              label="Cobro"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El cobro debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>

          <Box flex={1}>
            <TextInput
              id="ContrasenaCuentaInfonavit"
              label="Contraseña Cuenta Infonavit"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="CorreccionRFC"
              label="Corrección RFC"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="ActaNacimiento"
              label="Acta de Nacimiento"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="RFC"
              label="RFC"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Taller"
              label="Taller"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="ComprobanteDomicilio"
              label="Comprobante de domicilio"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Adelanto"
              label="Adelanto"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El adelanto debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="GastosAdmin"
              label="Gastos Administrativos"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message:
                    "Los gastos administrativos debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="SegundoDeposito"
              label="Segundo Deposito"
              type="text"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
              fullWidth
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="MontoDescarga"
              label="Monto de descarga"
              type="text"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El monto de descarga debe ser un número válido.",
                },
              }}
              errors={errors}
              fullWidth
            />
          </Box>
        </Box>
        <Box textAlign="center" mt={3}>
          <Button
            type="button"
            variant="contained"
            onClick={customSubmit}
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "#ffffff",
            }}
            fullWidth={isSmallScreen}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar datos de Mejoravit"
            )}
          </Button>

          {message && (
            <div className={`alert alert-${severity}`} role="alert">
              {message}
            </div>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default MejoravitCampos;
