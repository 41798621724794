import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Chip,
} from "@mui/material";
import axios from "axios";

const StatusSelect = ({ Nivel, onChange, Admin }) => {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  // Define color mapping for each status
  const statusColors = {
    1: "info",
    2: "success",
    3: "error",
    4: "warning", // FINALIZADO
    5: "darkred", // Rechazado
  };
  

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/clientes/statuses`);
        setStatuses(response.data);
        //console.log("estatus", response.data);
      } catch (error) {
        //console.error('Error fetching statuses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatuses();
  }, [Nivel]);

  const handleChange = (event) => {
    const newStatus = event.target.value;
    onChange(newStatus); // Notify parent about the change
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="status-select-label" sx={{ fontSize: '0.875rem' }}>
        Status
      </InputLabel>
      {loading ? (
        <CircularProgress />
      ) : (
        <Select
          readOnly={Admin}
          labelId="status-select-label"
          id="status-select"
          value={Nivel || ""}
          onChange={handleChange}
          label="Status"
          sx={{
            color: "#000000",
            fontSize: '0.875rem', // Ajusta el tamaño de la letra del select
          }}
        >
          {statuses.map((status) => (
            <MenuItem
              key={status.ArticuloID}
              value={status.ArticuloID}
              sx={{ fontSize: '0.875rem' }} // Ajusta el tamaño de la letra en las opciones
            >
              <Chip
                label={status.Nombre}
                color={statusColors[status.ArticuloID] || "default"} // Assign color based on status ID
                sx={{
                  color: "#ffffff", // Texto blanco en el chip
                  backgroundColor: statusColors[status.ArticuloID], // Color del chip basado en el status
                  fontSize: '0.75rem', // Tamaño de letra del chip
                }}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
  
};

export default StatusSelect;
