import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Paper,
} from "@mui/material";
import axios from "axios";
import ClearIcon from '@mui/icons-material/Clear'; // Importar el icono para limpiar

const FilterModal = ({ open, onClose, onApply }) => {
  const [selectedServicio, setSelectedServicio] = useState("");
  const [selectedOficina, setSelectedOficina] = useState("");
  const [selectedPuesto, setSelectedPuesto] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [asesor, setAsesor] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [servicios, setServicios] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [asesores, setAsesores] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios.get("/oficinas/Oficinas").then((res) => setOficinas(res.data));
    axios.get("/usuarios/Servicios").then((res) => setServicios(res.data));
  }, []);
  
  useEffect(() => {
    if (selectedOficina) {
      axios
        .get(`/KPI/reportesFiltro/Asesores?OficinaID=${selectedOficina}`)
        .then((res) => setAsesores(res.data))
        .catch((err) => console.error("Error al obtener asesores:", err));
    }
  }, [selectedOficina]);

  const handleApply = (e) => {
    e.preventDefault();

    // Validación de fechas
    if (fechaInicio && fechaFin && new Date(fechaFin) <= new Date(fechaInicio)) {
      setError("La fecha de fin debe ser posterior a la fecha de inicio.");
      return;
    } else {
      setError("");
    }

    onApply({
      servicio: selectedServicio,
      oficina: selectedOficina,
      puesto: selectedPuesto,
      asesor: asesor,
      fechaInicio,
      fechaFin,
    });
    onClose();
  };

  // Función para limpiar todos los campos
  const handleClear = () => {
    setSelectedServicio("");
    setSelectedOficina("");
    setSelectedPuesto("");
    setAsesor("");
    setFechaInicio("");
    setFechaFin("");
    setError("");
  };

  const handlePuestoChange = (e) => {
    const value = e.target.value;
    setSelectedPuesto(value);

    if (value) {
      setAsesor(""); // Limpiar asesor si se selecciona un puesto
    }
  };

  const handleAsesorChange = (e) => {
    const value = e.target.value;
    setAsesor(value);

    if (value) {
      setSelectedPuesto(""); // Limpiar puesto si se selecciona un asesor
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Filtrar Reportes
        </Typography>

        <form onSubmit={handleApply}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Servicio</InputLabel>
                <Select
                  value={selectedServicio}
                  onChange={(e) => setSelectedServicio(e.target.value)}
                >
                  <MenuItem value="">Todos los servicios</MenuItem>
                  {servicios
                    .filter((servicio) => servicio.ServicioID <= 4)
                    .map((servicio) => (
                      <MenuItem key={servicio.ServicioID} value={servicio.ServicioID}>
                        {servicio.Nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Oficina</InputLabel>
                <Select
                  value={selectedOficina}
                  onChange={(e) => setSelectedOficina(e.target.value)}
                >
                  <MenuItem value="">Todas las oficinas</MenuItem>
                  {oficinas.map((oficina) => (
                    <MenuItem key={oficina.OficinaID} value={oficina.OficinaID}>
                      {oficina.Nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" disabled={!selectedOficina}>
                <InputLabel>Puesto</InputLabel>
                <Select
                  value={selectedPuesto}
                  onChange={handlePuestoChange}
                >
                  <MenuItem value="">Todos los puestos</MenuItem>
                  <MenuItem value="1">Asesor</MenuItem>
                  {servicios
                    .filter((servicio) => servicio.ServicioID === 5 || servicio.ServicioID === 7)
                    .map((servicio) => (
                      <MenuItem key={servicio.ServicioID} value={servicio.ServicioID}>
                        {servicio.Nombre}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Asesor */}
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal" disabled={!selectedOficina}>
                <InputLabel>Asesor</InputLabel>
                <Select
                  value={asesor}
                  onChange={handleAsesorChange}
                >
                  <MenuItem value="">Todos los asesores</MenuItem>
                  {asesores.map((asesorItem) => (
                    <MenuItem key={asesorItem.AsesorID} value={asesorItem.AsesorID}>
                      {asesorItem.Nombres}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                type="date"
                label="Fecha Inicio"
                InputLabelProps={{ shrink: true }}
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                type="date"
                label="Fecha Fin"
                InputLabelProps={{ shrink: true }}
                value={fechaFin}
                onChange={(e) => setFechaFin(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
            <Button variant="outlined" onClick={handleClear} startIcon={<ClearIcon />}>
              Limpiar
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Aplicar
            </Button>
          </Box>
        </form>
      </Paper>
    </Modal>
  );
};

export default FilterModal;
