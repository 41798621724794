import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import axios from "axios";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import FilterModal from "./FilterModal";

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const BarChartWithModal = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [aux, setAux] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);  // Nuevo estado para controlar si el filtro fue aplicado

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Función para obtener los datos sin filtro (cuando no hay filtro aplicado)
  const fetchData = async () => {
    try {
      setLoading(true);  // Activar carga al solicitar los datos
      const response = await axios.get("/KPI/reportesFiltro/data", { withCredentials: true });
      const oficinasServicios = response.data;
      console.log("Datos de oficinas y servicios:", oficinasServicios);

      const oficinasLabels = oficinasServicios.map(oficina => oficina.oficinaNombre);

      const datasets = [
        {
          label: 'Mejoravit',
          data: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Mejoravit')?.cantidad || 0),
          backgroundColor: '#FB8C00',
          totalSaldo: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Mejoravit')?.Saldo || 0)
        },
        {
          label: 'Linea2',
          data: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Linea2')?.cantidad || 0),
          backgroundColor: '#673AB7',
          totalSaldo: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Linea2')?.Saldo || 0)
        },
        {
          label: 'Infonavit',
          data: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Infonavit')?.cantidad || 0),
          backgroundColor: '#5C6BC0',
          totalSaldo: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Infonavit')?.Saldo || 0)
        },
        {
          label: 'Embarazadas',
          data: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Embarazadas')?.cantidad || 0),
          backgroundColor: '#EC407A',
          totalSaldo: oficinasServicios.map(oficina => oficina.servicios.find(s => s.servicio === 'Embarazadas')?.Saldo || 0)
        }
      ];

      setData({ labels: oficinasLabels, datasets });
    } catch (error) {
      console.error("Error al obtener datos:", error);
    } finally {
      setLoading(false);  // Desactivar carga una vez obtenidos los datos
    }
  };

  // Efecto para obtener los datos al cargar el componente
  useEffect(() => {
    console.log("Aux:", aux);
    if (!aux && !filterApplied) {
      fetchData();
    }
  }, [aux, filterApplied]); // Dependencia de filterApplied

  // Función para aplicar el filtro
  const Filtro = async (info) => {
    setAux(true); // Impedir refetching hasta que el filtro se aplique

    // Si el filtro está vacío, reiniciar la petición de datos
    if (info.fechaFin === "" && info.fechaInicio === "" && info.oficina === "" && info.puesto === "" && info.servicio === "") {
      setFilterApplied(false);  // Reiniciar filtro
      fetchData(); // Vuelve a obtener los datos completos sin filtro
    } else {
      setFilterApplied(true);  // Filtro aplicado
      try {
        const response = await axios.post("/KPI/reportesFiltro/Filtro", info, { withCredentials: true });
        const asesoresServicios = response.data;

        // Crear las etiquetas (nombreCompleto) para cada asesor
        const asesoresLabels = asesoresServicios.map(asesor => `${asesor.nombreCompleto}`);

        // Crear los datasets para cada servicio
        const datasets = [
          {
            label: 'Mejoravit',
            data: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Mejoravit')?.cantidad || 0),
            backgroundColor: '#FB8C00',
            totalSaldo: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Mejoravit')?.saldo || 0)
          },
          {
            label: 'Linea2',
            data: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Linea2')?.cantidad || 0),
            backgroundColor: '#673AB7',
            totalSaldo: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Linea2')?.saldo || 0)
          },
          {
            label: 'Infonavit',
            data: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Infonavit')?.cantidad || 0),
            backgroundColor: '#5C6BC0',
            totalSaldo: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Infonavit')?.saldo || 0)
          },
          {
            label: 'Embarazadas',
            data: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Embarazadas')?.cantidad || 0),
            backgroundColor: '#EC407A',
            totalSaldo: asesoresServicios.map(asesor => asesor.servicios.find(s => s.servicio === 'Embarazadas')?.saldo || 0)
          }
        ];

        setData({ labels: asesoresLabels, datasets });  // Actualizar los datos con el filtro aplicado
      } catch (error) {
        console.error("Error al aplicar el filtro:", error);
      } finally {
        setAux(false); // Permitir futuras solicitudes después del filtro
      }
    }
  };

  // Configuración de las opciones de la gráfica
  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => {
            const totalSaldo = context.dataset.totalSaldo[context.dataIndex];
            return `${context.dataset.label}: ${context.raw} (Saldo: ${totalSaldo})`;
          },
        },
      },
    },
    scales: {
      x: { stacked: false },
      y: { stacked: false },
    },
  };

  return (
    <Card sx={{ position: "relative", padding: 2, maxWidth: "100%", margin: "0 auto" }}>
      <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={() => setOpenModal(true)}>
        <SettingsIcon />
      </IconButton>
      <CardContent>
        <Typography variant="h6" gutterBottom align="center">
          Reportes
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : data ? (
          <Box sx={{ height: isSmallScreen ? "250px" : "400px", width: "100%" }}>
            <Bar data={data} options={options} />
          </Box>
        ) : (
          <Typography variant="body2" align="center">No hay datos disponibles.</Typography>
        )}
      </CardContent>
      <FilterModal open={openModal} onClose={() => setOpenModal(false)} onApply={Filtro} />
    </Card>
  );
};

export default BarChartWithModal;
