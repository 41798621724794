import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import axios from "axios";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Box,
  Button,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // Asegúrate
import LocationForm from "../Muncipios";
import TextInput from "../TextInput";
import ProveedorSelect from "../ProveedorSelect";
import WPIGFBSelect from "../WPIGFBSelect";

const EmbarazadasCampos = ({
  selectedServicio,
  clienteID,
  theme,
  EsGerente,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext(); // Desestructuramos getValues de useFormContext

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [visita, setVisita] = useState("");
  const [proveedor, setProveedor] = useState("");
  const [wpigfb, setWPIGFB] = useState("");
  const [Municipio, setMunicipio] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [locationData, setLocationData] = useState({
    municipio: "",
    estado: "",
    codigoPostal: "",
    localidad: "",
  });
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const fetchData = async () => {
      if (clienteID) {
        try {
          const response = await axios.get(
            `/services/BuscarEmbarazadas/${clienteID}`,
            {
              withCredentials: true,
            }
          );

          //console.log("Respuesta:", response);
          reset(response.data);
          setVisita(response.data.Visita || "");
          setProveedor(response.data.ProveedorID || "");
          setWPIGFB(response.data.WB_IG_FB || "");
          setLocalidad(response.data.LocalidadID || "");
          setMunicipio(response.data.MunicipioID || "");
          setCodigoPostal(response.data.CodigoPostal || "");
          setLocationData({
            estado: response.data.EstadoID || "",
            municipio: response.data.MunicipioID || "",
            codigoPostal: response.data.CodigoPostal || "",
            localidad: response.data.LocalidadID || "",
          });
          setMessage(response.data.message || "");
        } catch (error) {
          //console.error("Error al cargar los datos del cliente:", error);
          setSubmitError("Hubo un error al cargar los datos del cliente.");
          setMessage("Algunos de los campos esta incorrecto o hace falta");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [clienteID, reset]);

  useEffect(() => {
    if (selectedServicio) {
      const fieldsToReset = [
        "SemanasGestacion",
        "Municipio",
        "FechaRecepcion",
        "FirmaContrato",
        "FechaAlta",
      ];

      const resetSelective = () => {
        const resetValues = fieldsToReset.reduce((acc, field) => {
          acc[field] = "";
          return acc;
        }, {});

        reset((prevValues) => ({ ...prevValues, ...resetValues }));
      };

      resetSelective();
    }
  }, [selectedServicio, reset]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const linkTo = `/Documentos/${clienteID}/${selectedServicio}`;

  const handleLocationChange = (data) => {
    setLocationData(data); // Actualiza el estado con los valores de LocationForm
  };

  const onSubmit = async (data) => {
    console.log(data);
    setSubmitLoading(true);
    setSubmitError(null);

    const formData = {
      ...data,
      ...locationData,
    };

    try {
      const response = await axios.post(
        `/services/GuardarEmbarazadas/${clienteID}`,
        formData,
        { withCredentials: true }
      );
      setMessage(response.data.message);
      return response;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        console.error(`Error ${status}:`, data);

        if (status === 400) {
          setSubmitError("Solicitud inválida. Verifica los datos ingresados.");
        } else if (status === 404) {
          setSubmitError("Recurso no encontrado. Verifica el cliente ID.");
        } else {
          setSubmitError(
            "Hubo un error al guardar los datos. Inténtalo de nuevo."
          );
        }
      } else {
        console.error("Error desconocido:", error);
        setSubmitError("Error inesperado. Por favor, inténtalo nuevamente.");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleClick = async () => {
    try {
      const formData = getValues(); // Obtén los valores del formulario
      const result = await onSubmit(formData); // Ejecuta el submit con los valores del formulario
      console.log("Respuesta: 2", result);

      // Verifica si el resultado es exitoso con un código HTTP 2xx
      if (result && result.status >= 200 && result.status < 300) {
        console.log("Fue exitoso");
        // Redirigir al enlace deseado
        navigate(linkTo);
      } else {
        console.error("Estado HTTP inesperado:", result?.status);
        const errorMessage = `¡Error! Algunos campos están vacíos o son incorrectos:
          • Semanas de Gestación
          • Estado
          • Código Postal
          • Localidad
          • Proveedor
          • Redes
        Por favor, verifica estos campos e intenta nuevamente.
        `;
        alert(errorMessage);

        // Manejo adicional si es necesario
      }
    } catch (error) {
      // Manejo de errores generales
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          console.error("Error 400: Solicitud incorrecta", error.response.data);
        } else if (status === 404) {
          console.error(
            "Error 404: Recurso no encontrado",
            error.response.data
          );
        } else {
          console.error(`Error ${status}:`, error.response.data);
        }
      } else {
        // Si el error es de red o algún fallo inesperado
        console.error(
          "Error de red o fallo de conexión:",
          error.message || error
        );
      }
    }
  };

  const severity = submitError ? "error" : message ? "success" : "";

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={200}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <hr style={{ margin: "20px 0", border: "2px solid #17202a" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        flexDirection={isSmallScreen ? "column" : "row"}
      >
        <h1>Datos de Embarazadas</h1>
        <Box>
          <Tooltip title="Subir documentos">
            <IconButton
              size="large" // Tamaño grande
              style={{
                color: "#1976d2", // Cambia el color del ícono si es necesario
                backgroundColor: "#e3f2fd", // Color de fondo para hacerlo más visible
                borderRadius: "50%", // Forma circular para mayor impacto visual
                padding: "16px", // Espaciado adicional para que el ícono sea más grande
              }}
              onClick={handleClick} // Ejecuta la función al hacer clic
            >
              <NoteAddIcon style={{ fontSize: 40 }} />{" "}
              {/* Ajusta el tamaño del ícono aquí */}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              id="SemanasGestacion"
              label="Semanas de Gestación"
              register={register}
              color={theme.palette.background.default}
              required={true}
              maxLength={2}
              errors={errors}
              validation={{
                required: "Este campo es obligatorio", // Add required validation message
                pattern: {
                  value: /^[0-9]{1,2}$/, // Validate for numbers with a max length of 2 digits
                  message:
                    "Las semanas de gestación tienen que ser un número entero.",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          {" "}
          <Box flex={1}>
            <LocationForm
              required={true}
              initialCodigoPostal={codigoPostal}
              initialMunicipioID={Municipio}
              initialLocalidadID={localidad}
              theme={theme}
              onLocationChange={handleLocationChange}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
             
              id="FechaRecepcion"
              label="Fecha de Recepción"
              type="date"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              
              id="FirmaContrato"
              label="Firma de Contrato"
              type="date"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              
              id="FechaAlta"
              label="Fecha de Alta"
              type="date"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <ProveedorSelect
              required={true}
              selectedServicio={selectedServicio}
              Proveedor={proveedor}
              color={theme.palette.background.default}
              Letras={"#000000"}
            />
          </Box>
          <Box flex={1}>
            <WPIGFBSelect
              required={true}
              wpigfb={wpigfb}
              color={theme.palette.background.default}
              Letras={"#000000"}
            />
          </Box>
          <Box flex={1}>
            <FormControl
              fullWidth
              variant="filled"
              margin="normal"
              error={Boolean(errors.Visita)}
            >
              <InputLabel id="visita-label" style={{ color: "#000000" }}>
                Visita *
              </InputLabel>
              <Select
              readOnly={EsGerente}
                labelId="visita-label"
                id="Visita"
                defaultValue={visita || ""}
                {...register("Visita")}
                style={{
                  background: theme.palette.background.default, // Color de fondo del Select
                  color: "#000000", // Color del texto
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: theme.palette.background.default, // Fondo del menú desplegable
                    },
                  },
                }}
                renderValue={(selected) => (
                  <span style={{ color: "#000000" }}>
                    {selected === ""
                      ? "Seleccione"
                      : selected === 1
                      ? "SI"
                      : "NO"}
                  </span>
                )}
              >
                <MenuItem value="" style={{ color: "#000000" }} disabled>
                  <em>Seleccione</em>
                </MenuItem>
                <MenuItem value={1} style={{ color: "#000000" }}>
                  SI
                </MenuItem>
                <MenuItem value={2} style={{ color: "#000000" }}>
                  NO
                </MenuItem>
              </Select>
              <FormHelperText>{errors.Visita?.message || ""}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              id="FechaParto"
              label="Fecha probable de Parto"
              type="date"
              register={register}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="Salario"
              label="Salario"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El salario debe ser un número válido.",
                },
              }}
              errors={errors}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              Gerente={EsGerente}
              id="MontoIncapacidad"
              label="Monto Incapacidad"
              register={register}
              color={theme.palette.background.default}
              validation={{
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/, // Validar números con punto decimal
                  message: "El monto de incapacidad debe ser un número válido.",
                },
              }}
              errors={errors}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          gap={2}
          mb={2}
          flexDirection={isSmallScreen ? "column" : "row"}
        >
          <Box flex={1}>
            <TextInput
              id="Direccion"
              label="Direccion"
              register={register}
              multiline
              rows={4}
              color={theme.palette.background.default}
              errors={errors}
            />
          </Box>
        </Box>
        <Box textAlign="center" mt={3}>
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "#ffffff",
            }}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Guardar datos de Embarazadas"
            )}
          </Button>
          {message && (
            <div className={`alert alert-${severity}`} role="alert">
              {message}
            </div>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default EmbarazadasCampos;
