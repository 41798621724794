import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import Menu from "./components/Menu"; // Asegúrate de que la ruta sea correcta
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";

// Gráficas
import PorProcesar from "./components/Graficas/PorProcesar"; // Asegúrate de que la ruta sea correcta
import Detenidos from "./components/Graficas/Detenidos";
import MejoravitsPorProcesar from "./components/Graficas/MejoravitsEnProceso";
import InfonavitPorProcesar from "./components/Graficas/InfonavitsEnProceso";
import Linea2PorProcesar from "./components/Graficas/Linea2EnProceso";
import EmbarazadasPorProcesar from "./components/Graficas/EmbarazadasEnProceso";
import MejoravitsDetenidos from "./components/Graficas/MejoravitsDetenidos";
import InfonavitDetenidos from "./components/Graficas/InfonavitsDetenidos";
import Linea2Detenidos from "./components/Graficas/Linea2Detenidos";
import EmbarazadasDetenidos from "./components/Graficas/EmbarazadasDetenidos";
import RegistrosConFiltro from "./components/Graficas/RegistrosConFiltro";

// Para el carrusel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

function Dashboard() {
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.post(
          "/usuarios/getPermissions",
          {},
          { withCredentials: true }
        );
        if (response.data.success) {
          setPermissions(response.data.permissions);
        }
      } catch (error) {
        //console.error(error);
      }
    };
    fetchPermissions();
  }, []);

  const settings = {
    dots: true, // Mostrar puntos de navegación
    infinite: true, // Ciclo infinito
    speed: 500, // Velocidad de transición
    slidesToShow: 1, // Muestra un componente a la vez
    slidesToScroll: 1, // Cambia de uno en uno
    nextArrow: <CustomNextArrow />, // Flecha personalizada hacia adelante
    prevArrow: <CustomPrevArrow />, // Flecha personalizada hacia atrás
  };

  const showCarousel = permissions.includes("1");

  return (
    <>
      <header>
        <Menu />
      </header>
      <Box sx={{ padding: "20px", backgroundColor: "#f4f4f4", minHeight: "100vh" }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ marginBottom: "20px", color: "#333" }}>
          Información
        </Typography>

        <Grid container spacing={3}>
          {/* Solo mostrar carrusel si el permiso es 1 */}
          {showCarousel && (
            <>
              {/* Carrusel de EnProceso */}
              <Grid item xs={12} sm={6} md={6}>
                <div className="container">
                  <Slider {...settings}>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <PorProcesar /> {/* Componente PorProcesar */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <MejoravitsPorProcesar /> {/* Componente MejoravitsPorProcesar */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <InfonavitPorProcesar /> {/* Componente InfonavitPorProcesar */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <Linea2PorProcesar /> {/* Componente Linea2PorProcesar */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <EmbarazadasPorProcesar /> {/* Componente EmbarazadasPorProcesar */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </Grid>

              {/* Carrusel de Detenidos */}
              <Grid item xs={12} sm={6} md={6}>

                <div className="container">
                  <Slider {...settings}>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <Detenidos /> {/* Componente Detenidos */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <MejoravitsDetenidos /> {/* Componente MejoravitsDetenidos */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <InfonavitDetenidos /> {/* Componente InfonavitDetenidos */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <Linea2Detenidos /> {/* Componente Linea2Detenidos */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <EmbarazadasDetenidos /> {/* Componente EmbarazadasDetenidos */}
                      </div>
                    </div>
                  </Slider>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <div className="container">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <RegistrosConFiltro /> {/* Componente RegistrosConFiltro */}
                      </div>
                    </div>
                </div>
              </Grid>


            </>
          )}

          {/* Siempre mostrar En Proceso y Detenidos */}
          {!showCarousel && (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h5" gutterBottom align="center" sx={{ marginBottom: "20px", color: "#333" }}>
                  En Proceso
                </Typography>
                <PorProcesar /> {/* Componente PorProcesar */}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="h5" gutterBottom align="center" sx={{ marginBottom: "20px", color: "#333" }}>
                  Detenidos
                </Typography>
                <Detenidos /> {/* Componente Detenidos */}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}

// Flecha personalizada hacia adelante
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        right: "-25px",
        zIndex: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      ➡️
    </div>
  );
};

// Flecha personalizada hacia atrás
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "-25px",
        zIndex: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      ⬅️
    </div>
  );
};

export default Dashboard;
